<template>
  <div v-if="isshowgrid">
    <va-card title="External App List">
      <div class="row align--center">
        <div class="flex xs12 md6">
          <va-input class="va-input-search-container"
            :value="term"
            placeholder="search"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>
        <div class="flex xs12 md3 offset--md3">
          <va-button color="success" icon="fa fa-plus" v-if='!supportRole' @click="add()">Add</va-button>
        </div>
      </div>
      <va-data-table
        :fields="fields"
        :data="filteredData"
        :per-page="parseInt(perPage)"
        clickable
      >
        <template slot="actions" slot-scope="props">
          <va-button flat small color="gray" icon="fa fa-pencil" v-if ='!supportRole' @click="edit(props.rowData)" class="ma-0"></va-button>
        </template>
      </va-data-table>
    </va-card>
  </div>
  <div v-else-if='isshowForm'>
    <div>
      <va-card :title="title">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <div>
          <form>
            <span class="va-form-label va-form-required-label">Name</span>
            <va-input
              v-model.trim="name"
              type="text"
              placeholder="Enter Name"
              :error="!!nameErrors.length"
              :error-messages="nameErrors"
            />
            <span class="va-form-label va-form-required-label">Package Name</span>
            <va-input
              v-model.trim="package_name"
              type="text"
              placeholder="Enter Package Name"
              :error="!!packageNameErrors.length"
              :error-messages="packageNameErrors"
            />
            <span class="va-form-label">Monthly Code</span>
            <va-input
              v-model.trim="monthly_code"
              type="text"
              placeholder="Enter Monthly code"
            />
            <span class="va-form-label">Quarterly Code</span>
            <va-input
              v-model.trim="quarterly_code"
              type="text"
              placeholder="Enter Quarterly code"
            />
            <span class="va-form-label">Halfyearly Code</span>
            <va-input
              v-model.trim="halfyearly_code"
              type="text"
              placeholder="Enter Halfyearly code"
            />
            <span class="va-form-label">Yearly Code</span>
            <va-input
              v-model.trim="yearly_code"
              type="text"
              placeholder="Enter Yearly code"
            />
            <span v-if="isCreate" class="va-form-label va-form-required-label">Logo</span>
            <span v-if="isUpdate" class="va-form-label">Logo</span>
            <va-file-upload
              type="single"
              dropzone
              v-model="logo_file"
            />
            <va-checkbox
              v-model="is_mobile"
              label="Mobile"
            />
            <span v-if="is_mobile" class="va-form-label va-form-required-label">Mobile Link</span>
            <va-input
              v-if="is_mobile"
              v-model.trim="mobile_link"
              type="text"
              placeholder="Enter Mobile Link"
              :error="!!mobileLinkErrors.length"
              :error-messages="mobileLinkErrors"
            />
            <va-checkbox
              v-model="is_tv"
              label="TV"
            />
            <span v-if="is_tv" class="va-form-label va-form-required-label">TV Link</span>
            <va-input
              v-if="is_tv"
              v-model.trim="tv_link"
              type="text"
              placeholder="Enter TV Link"
              :error="!!tvLinkErrors.length"
              :error-messages="tvLinkErrors"
            />
            <span class="va-form-label va-form-required-label">Activation Type</span>
              <div class="row" style="padding-left: 10px;">
                <va-radio-button
                  option="Coupon"
                  v-model="activation_type"
                  label="Coupon"
                  @input="activationtype(activation_type)"
                />
                <va-radio-button
                  option="Subscription"
                  v-model="activation_type"
                  label="Subscription"
                  @input="activationtype(activation_type)"
                />
              </div>
            <div class="flex xs12">
              <label v-if="show_sms_templete" class="va-form-label va-form-required-label">SMS Message Template</label><span v-if="show_sms_templete" class="va-form-label"> (Mention the Name as **** and Coupon as xxxx)</span><br>
              <textarea v-if="show_sms_templete && isCreate" class="text_box" v-model="sms_template" rows="5" cols="140" />
              <textarea v-if="show_sms_templete && isUpdate" class="text_box" v-model="sms_template" rows="5" cols="140" />
            </div>  
            <span class="va-form-label va-form-required-label">Link</span>
            <va-input
              v-model.trim="instruction_link"
              type="text"
              placeholder="Enter your link"
              :error="!!linkErrors.length"
              :error-messages="linkErrors"
            />
            <span class="va-form-label va-form-required-label">OTT Code</span>
            <va-input
              v-model.trim="ott_code"
              type="text"
              placeholder="Enter OTT Code"
              :error="!!ottCodeErrors.length"
              :error-messages="ottCodeErrors"
            />
            <div class="d-flex justify--end mt-3">
              <va-button type="submit" class="my-0 va-button-normal-cancel" @click.prevent="list()">Cancel</va-button>
              <va-button v-if="isCreate" type="submit" class="my-0" @click.prevent="createExternalApp()">Create</va-button>
              <va-button v-if="isUpdate" type="submit" class="my-0" @click.prevent="updateExternalApp()">Update</va-button>
            </div>
          </form>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
import { debounce } from 'lodash'
Vue.use(vueResource)
export default {
  name: 'external_app',
  created () {
    this.getAllExternalApp()
    // const loader = Vue.$loading.show({ width: 40, height: 40 })
    // this.$http.get(config.menu.host + '/external_app').then(response => {
    //   loader.hide()
    //   this.externalAppData = response.body
    //   this.isshowForm = false
    //   this.isshowgrid = true
    //   this.getAllExternalApp()
    // }, error => {
    //   loader.hide()
    //   if (error && error.body) {
    //     Vue.notify({ text: error.body, type: 'error' })
    //   }
    // })
  },
  data () {
    return {
      term: null,
      perPage: '10',
      perPageOptions: ['4', '6', '10', '20'],
      isshowgrid: false,
      isshowForm: false,
      isCreate: false,
      isUpdate: false,
      is_mobile: false,
      show_sms_templete: false,
      is_tv: false,
      name: '',
      tv_link: '',
      mobile_link: '',
      package_name: '',
      instruction_link: '',
      title: '',
      activation_type: '',
      sms_template: '',
      externalAppData: [],
      logo_file: [],
      nameErrors: [],
      packageNameErrors: [],
      mobileLinkErrors: [],
      tvLinkErrors: [],
      monthlyCodeErrors: [],
      linkErrors: [],
      monthly_code: '',
      quarterly_code: '',
      quarterlyCodeErrors: [],
      halfyearly_code: '',
      halfyearlyCodeErrors: [],
      yearly_code: '',
      yearlyCodeErrors: [],
      activationTypeErrors:[],
      supportRole:false,
      ott_code: '',
      ottCodeErrors: []
    }
  },
  computed: {
    Check_device () {
      if (this.is_mobile && this.is_tv) {
        return true
      } else if (this.is_mobile || this.is_tv) {
        return true
      } else {
        return false
      }
    },
    Check_type () {
      if (this.activation_type) {
        return true
      } else {
        Vue.notify({ text: 'please Select Activation Type', type: 'error' })
        return false
      }
    },
    Check_upload () {
      if (this.logo_file[0]) {
        return true
      } else {
        Vue.notify({ text: 'please Upload images', type: 'error' })
        return false
      }
    },
    formReady () {
      return !this.nameErrors.length && !this.packageNameErrors.length  && !this.linkErrors.length
    },
    fields () {
      return [{
        name: 'sno',
        title: 'S.NO',
        width: '6%',
      }, {
        name: '__slot:marker',
        width: '30px',
        dataClass: 'text-center',
      },
      {
        name: 'name',
        title: 'Name',
      },
      {
        name: 'package_name',
        title: 'Package Name',
      },
      {
        name: 'link',
        name: 'instruction_link',
      },
      {
        name: 'activation_type',
        title: 'Activation Type',
      },
      {
        name: '__slot:actions',
        dataClass: 'text-right',
      }]
    },
    filteredData () {
      return search(this.term, this.externalAppData)
    },
  },
  methods: {
    getAllExternalApp () {
      var role = this.$cookies.get('userRole')
      if(role=="SUPPORT"){
        this.supportRole=true
      }
      this.$http.get(config.menu.host + '/external_app').then(response => {
        let index = 0
        this.externalAppData = response.body.map(function (item) {
          item.id = index++
          item.sno = index
          item.is_existing = true
          item.checkbox_value = false
          return item
        })
        this.isshowForm = false
        this.isshowgrid = true
      })
    },
    createExternalApp () {
      this.nameErrors = this.name ? [] : ['Name is required']
      this.packageNameErrors = this.package_name ? [] : ['Package Name is required']
      this.monthlyCodeErrors = this.monthly_code ? [] : ['Monthly Code is required']
      this.quarterlyCodeErrors = this.quarterly_code ? [] : ['Quarterly Code is required']
      this.halfyearlyCodeErrors = this.halfyearly_code ? [] : ['Halfyearly Code is required']
      this.yearlyCodeErrors = this.yearly_code ? [] : ['Yearly Code is required']
      this.linkErrors = this.instruction_link ? [] : ['Link is required']
      this.ottCodeErrors = this.ott_code ? [] : ['ott code is required']
    
      if (!this.formReady) {
        return
      }
      if (!this.Check_upload) {
        return
      }
      if (!this.Check_type) {
        return
      }
      if (!this.Check_device) {
        return Vue.notify({ text: 'Please select mobile or tv app', type: 'error' })
      }
      if (this.is_mobile && !this.mobile_link) {
        return Vue.notify({ text: 'Please enter mobile link', type: 'error' })
      }
      if(this.show_sms_templete){
       if(this.sms_template.length == 0){
          return Vue.notify({ text: 'SMS template is Required ', type: 'error' })
       }
      }
      if (this.is_tv && !this.tv_link) {
        return Vue.notify({ text: 'Please enter tv link', type: 'error' })
      }
      if((this.monthlyCodeErrors.length > 0) && (this.quarterlyCodeErrors.length > 0) && (this.halfyearlyCodeErrors.length > 0) && (this.yearlyCodeErrors.length > 0)) {
       return Vue.notify({ text: 'Please enter atleast one code', type: 'error' }) 
      }
      var payload = new FormData()
      payload.append('logo', this.logo_file[0])
      payload.append('name', this.name)
      payload.append('link', this.link)
      payload.append('package_name', this.package_name)
      payload.append('is_mobile', this.is_mobile)
      payload.append('is_tv', this.is_tv)
      payload.append('mobile_link', this.mobile_link)
      payload.append('tv_link', this.tv_link)
      payload.append('monthly_code', this.monthly_code)
      payload.append('quarterly_code', this.quarterly_code)
      payload.append('halfyearly_code', this.halfyearly_code)
      payload.append('yearly_code', this.yearly_code)
      payload.append('activation_type',this.activation_type)
      payload.append('sms_template',this.sms_template)
      payload.append('instruction_link', this.instruction_link)
      payload.append('ottCode', this.ott_code)

      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + '/external_app', payload).then(responseData => {
        loader.hide()
        if (responseData && responseData.body) {
          Vue.notify({ text: responseData.body, type: 'success' })
        }
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    activationtype(btn){
      if (btn == 'Coupon') {
        this.show_sms_templete = true
      } else {
        this.show_sms_templete = false
      }
    },
    updateExternalApp () {
      this.nameErrors = this.name ? [] : ['Name is required']
      this.packageNameErrors = this.package_name ? [] : ['Package Name is required']
      this.monthlyCodeErrors = this.monthly_code ? [] : ['Monthly Code is required']
      this.quarterlyCodeErrors = this.quarterly_code ? [] : ['Quarterly Code is required']
      this.halfyearlyCodeErrors = this.halfyearly_code ? [] : ['Halfyearly Code is required']
      this.yearlyCodeErrors = this.yearly_code ? [] : ['Yearly Code is required']
      this.linkErrors = this.instruction_link ? [] : ['Link is required']
      this.ottCodeErrors = this.ott_code ? [] : ['ott code is required']
      if (!this.formReady) {
        return
      }
      if (!this.Check_device) {
        return Vue.notify({ text: 'Please select mobile or tv app', type: 'error' })
      }
      if (this.is_mobile && !this.mobile_link) {
        return Vue.notify({ text: 'Please enter mobile link', type: 'error' })
      }
      if (this.is_tv && !this.tv_link) {
        return Vue.notify({ text: 'Please enter tv link', type: 'error' })
      }
      if(this.show_sms_templete){
         if(this.sms_template.length == 0){
            return Vue.notify({ text: 'SMS template is Required ', type: 'error' })
         }
      }
      var payload = new FormData()
      if (this.logo_file[0]) {
        payload.append('logo', this.logo_file[0])
      }
      if((this.monthlyCodeErrors.length > 0) && (this.quarterlyCodeErrors.length > 0) && (this.halfyearlyCodeErrors.length > 0) && (this.yearlyCodeErrors.length > 0)){
       return Vue.notify({ text: 'Please enter atleast one code', type: 'error' }) 
      }
      payload.append('name', this.name)
      payload.append('link', this.link)
      payload.append('package_name', this.package_name)
      payload.append('is_mobile', this.is_mobile)
      payload.append('is_tv', this.is_tv)
      payload.append('mobile_link', this.mobile_link)
      payload.append('tv_link', this.tv_link)
      payload.append('monthly_code', this.monthly_code)
      payload.append('quarterly_code', this.quarterly_code)
      payload.append('halfyearly_code', this.halfyearly_code)
      payload.append('yearly_code', this.yearly_code)
      payload.append('activation_type',this.activation_type)
      payload.append('sms_template',this.sms_template)
      payload.append('instruction_link', this.instruction_link)
      payload.append('ottCode', this.ott_code)

      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.put(config.menu.host + '/external_app/' + this.external_app_id, payload).then(responseData => {
        loader.hide()
        if (responseData && responseData.body) {
          Vue.notify({ text: responseData.body, type: 'success' })
        }
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    edit (row) {
      this.title = 'Update External App'
      this.logo_file = []
      this.external_app_id = row.external_app_id
      this.name = row.name
      this.mobile_link = row.mobile_link
      this.tv_link = row.tv_link
      this.is_tv = row.is_tv
      this.is_mobile = row.is_mobile
      this.activation_type = row.activation_type
      this.sms_template = row.sms_template
      this.package_name = row.package_name
      this.instruction_link = row.instruction_link
      this.ott_code = row.ottCode;
      if(this.activation_type == "Coupon"){
        this.show_sms_templete = true
      }else{
        this.show_sms_templete = false
      }
      this.monthly_code = row.monthly_code ? row.monthly_code : ''
      this.quarterly_code = row.quarterly_code ? row.quarterly_code : ''
      this.halfyearly_code = row.halfyearly_code ? row.halfyearly_code : ''
      this.yearly_code = row.yearly_code ? row.yearly_code : ''
      this.isshowgrid = false
      this.isshowForm = true
      this.isCreate = false
      this.isUpdate = true
      this.linkErrors = []
      this.nameErrors = []
      this.packageNameErrors = []
      this.ottCodeErrors = []
    },
    cancel () {
      this.showRemoveModal = false
    },
    add () {
      this.title = 'Create External App'
      this.name = ''
      this.package_name = ''
      this.instruction_link = ''
      this.mobile_link = ''
      this.mobileLinkErrors = []
      this.linkErrors = []
      this.tv_link = ''
      this.tvLinkErrors = []
      this.is_mobile = false
      this.is_tv = false
      this.nameErrors = []
      this.logo_file = []
      this.packageNameErrors = []
      this.monthly_code = ''
      this.monthlyCodeErrors = []
      this.quarterly_code = ''
      this.quarterlyCodeErrors = []
      this.halfyearly_code = ''
      this.activation_type = ''
      this.sms_template = ''
      this.halfyearlyCodeErrors = []
      this.yearly_code = ''
      this.yearlyCodeErrors = []
      this.activationTypeErrors = []
      this.show_sms_templete = false
      this.isshowForm = true
      this.isshowgrid = false
      this.isUpdate = false
      this.isCreate = true
      this.ottCodeErrors = []
      this.ott_code = ''
    },
    list () {
      this.isshowForm = false
      this.isshowgrid = true
      this.getAllExternalApp()
    },
    search: debounce(function (term) {
      this.term = term
    }, 400),
  },
}
</script>
